'use client';

import { useState, useCallback, ReactNode } from 'react';
import { usePathname } from 'next/navigation';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, ButtonProps, Menu, MenuItem, Typography, MenuItemProps, Link } from '@mui/material';
import { Rotate } from '@qb/frontend/components/Rotate';
export const NavbarItem = ({
  href,
  label,
  isParentActive,
  ...rest
}: ButtonProps & {
  label: ReactNode;
  isParentActive?: boolean;
}) => {
  const pathname = usePathname();
  const isActive = pathname === href || isParentActive;
  return <Button variant="text" color="tertiary" href={href} sx={{
    height: 44,
    px: 2,
    backgroundColor: isActive ? 'tertiary.4p' : undefined
  }} {...rest} data-sentry-element="Button" data-sentry-component="NavbarItem" data-sentry-source-file="NavbarItems.tsx">
      <Typography variant="h5" component="span" noWrap data-sentry-element="Typography" data-sentry-source-file="NavbarItems.tsx">
        {label}
      </Typography>
    </Button>;
};
const NavbarMenuItem = ({
  href,
  children,
  ...rest
}: MenuItemProps & {
  href?: string;
}) => {
  const pathname = usePathname();
  const isActive = pathname === href;
  return <MenuItem selected={isActive} {...rest} sx={{
    py: 1.5,
    px: 3
  }} data-sentry-element="MenuItem" data-sentry-component="NavbarMenuItem" data-sentry-source-file="NavbarItems.tsx">
      {href ? <Link color="text.primary" variant="bodyLarge" href={href} underline="none">
          {children}
        </Link> : children}
    </MenuItem>;
};
type ItemProps = {
  href: string;
  label: ReactNode;
};
type NavbarMenuProps = ItemProps & {
  menuItems: ItemProps[];
};
export const NavbarMenu = ({
  href,
  label,
  menuItems
}: NavbarMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  return <>
      <NavbarItem onClick={e => setAnchorEl(e.currentTarget)} href={href} label={label} isParentActive={isOpen} endIcon={<Rotate isRotated={isOpen}>
            <KeyboardArrowDownIcon sx={{
        color: 'text.primary'
      }} />
          </Rotate>} data-sentry-element="NavbarItem" data-sentry-source-file="NavbarItems.tsx" />
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose} sx={{
      filter: 'drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))',
      '& .MuiPaper-root': {
        mt: 1,
        borderWidth: 0
      }
    }} data-sentry-element="Menu" data-sentry-source-file="NavbarItems.tsx">
        {menuItems.map(({
        href,
        label
      }) => <NavbarMenuItem key={href} href={href} onClick={handleClose}>
            {label}
          </NavbarMenuItem>)}
      </Menu>
    </>;
};